const CUSTOM_MESSAGE = 'custom_message';
const NEED_VERIFY_OFFER = 'need_verify_offer';
const NEED_VERIFY_REQUEST = 'need_verify_request';
const SET_DELIVERY_PRICE = 'set_delivery_price';
const DELIVERY_INVOICE_FOR_BUYER = 'delivery_invoice_for_buyer';
const CLEARANCE_SELECTED = 'clearance_selected';
const GOODS_SENT = 'goods_sent';
const GOODS_RECEIVED = 'goods_received';
const DEAL_ISSUE = 'deal_issue';
const ISSUE_SOLVED = 'issue_solved';
const DEAL_CREATED = 'deal_created';
const RECEIPT_SENT = 'receipt_sent';
const NEED_VERIFY_OFFER_UPDATE = 'offer_should_be_reverified';
const OFFER_ACCEPTED = 'offer_accepted';
const OFFER_REJECTED = 'offer_rejected';
const NEED_VERIFY_USER_AGREEMENT = 'need_verify_user_agreement';
const AWAITING_DEAL_PAYMENT = 'awaiting_deal_payment';

export const TRADING = [
  NEED_VERIFY_OFFER,
  NEED_VERIFY_REQUEST,
  SET_DELIVERY_PRICE,
  DELIVERY_INVOICE_FOR_BUYER,
  CLEARANCE_SELECTED,
  GOODS_SENT,
  GOODS_RECEIVED,
  DEAL_ISSUE,
  ISSUE_SOLVED,
  DEAL_CREATED,
  RECEIPT_SENT,
  NEED_VERIFY_OFFER_UPDATE,
  OFFER_ACCEPTED,
  OFFER_REJECTED,
  AWAITING_DEAL_PAYMENT,
];

export const ACCOUNT = [
  NEED_VERIFY_USER_AGREEMENT,
];

export const CHAT = [
  DEAL_ISSUE,
];

export const getStatusByType = type => {
  switch (true) {
    case CHAT.includes(type):
      return 'danger';
    case ACCOUNT.includes(type):
      return 'warning';
    case TRADING.includes(type):
      return 'primary';
    default:
      return 'success';
  }
};
