<template>
  <div class="sidebar-left">
    <div class="sidebar">
      <div class="sidebar-content email-app-sidebar">
        <div class="email-app-menu">
          <vue-perfect-scrollbar
            :settings="perfectScrollbarSettings"
            class="sidebar-menu-list scroll-area"
          >
            <b-list-group class="list-group-labels">
              <b-list-group-item
                v-for="label in emailLabel"
                :key="label.title + $route.path"
                :to="label.route"
                :active="label.route.query.label === activeLabel"
              >
                <span
                  class="bullet bullet-sm mr-1"
                  :class="`bullet-${label.color}`"
                />
                <span class="font-weight-bold">
                  {{ label.title }}
                </span>
              </b-list-group-item>
            </b-list-group>
          </vue-perfect-scrollbar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import {
  BListGroup, BListGroupItem,
} from 'bootstrap-vue';
import { isDynamicRouteActive } from '@core/utils/utils';
import Ripple from 'vue-ripple-directive';
import i18n from '@/libs/i18n';

export default {
  directives: {
    Ripple,
  },
  components: {

    // BSV
    BListGroup,
    BListGroupItem,

    // 3rd Party
    VuePerfectScrollbar,
  },
  props: {
    activeLabel: {
      type: String,
      default: '',
    },
  },
  setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
    };

    const emailLabel = [
      { title: i18n.t('new'), color: 'success', route: { name: 'apps-notifications', query: { label: 'new' } } },
      { title: i18n.t('trading'), color: 'primary', route: { name: 'apps-notifications', query: { label: 'trading' } } },
      { title: i18n.t('account'), color: 'warning', route: { name: 'apps-notifications', query: { label: 'account' } } },
      { title: i18n.t('chat'), color: 'danger', route: { name: 'apps-notifications', query: { label: 'chats' } } },
    ];

    const resolveFilterBadgeColor = filter => {
      if (filter === 'Draft') return 'light-warning';
      if (filter === 'Spam') return 'light-danger';
      return 'light-primary';
    };

    return {
      // UI
      perfectScrollbarSettings,
      isDynamicRouteActive,
      resolveFilterBadgeColor,

      // Filter & Labels
      emailLabel,
    };
  },
};
</script>

<style lang="scss" scoped>

.sidebar-left {
  width: 100%;
}

</style>
