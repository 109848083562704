<template>
  <app-timeline>
    <notification-group
      v-for="(notificationGroup, idx) of notifications"
      :key="`notification_group_${idx}`"
      :notification-group="notificationGroup"
      @update="updateNotification"
    />
  </app-timeline>
</template>

<script>

import AppTimeline from '@core/components/app-timeline/AppTimeline.vue';
import NotificationGroup from '@/views/apps/notifications/NotificationGroup.vue';
import { goToNotificationRoute } from '@/utils/notificationsUtils.js';

export default {
  components: {
    NotificationGroup,
    AppTimeline,
  },
  props: {
    notifications: {
      type: Array,
      default: () => ([]),
    },
    paginationMeta: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    updateNotification(notification) {
      this.$emit('updateNotification', notification);
    },
  },
};
</script>
