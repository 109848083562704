<template>
  <div class="notifications-div">
    <b-card-code :title="$t('notifications')">
      <b-row>
        <b-col
          cols="2"
          sm="2"
        >
          <Sidebar :active-label="activeLabel" />
        </b-col>
        <b-col
          cols="10"
          sm="10"
        >
          <Content
            :notifications="sortNotifications"
            @updateNotification="updateNotification"
          />
        </b-col>
      </b-row>
      <div class="mx-2 mb-2 mt-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">
              {{ $t('tableDetails', { from: paginationMeta.from, to: paginationMeta.to, of: paginationMeta.of }) }}
            </span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="paginationMeta.current_page"
              :total-rows="paginationMeta.total"
              :per-page="paginationMeta.per_page"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @input="getNotification"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card-code>
  </div>
</template>
<script>
import BCardCode from '@core/components/b-card-code';
import axios from '@/libs/axios.js';
import { BPagination, BRow, BCol } from 'bootstrap-vue';
import { goToNotificationRoute } from '@/utils/notificationsUtils.js';
import { ACCOUNT, CHAT, TRADING } from '@/constants/notifications.js';
import Content from './Content.vue';
import Sidebar from './Sidebar.vue';

export default {
  components: {
    BCardCode,
    Sidebar,
    Content,
    BCol,
    BRow,
    BPagination,
  },
  data() {
    return {
      notifications: [],
      paginationMeta: {
        current_page: 1,
        per_page: 10,
        total: 0,
        from: 0,
        to: 0,
      },
    };
  },
  computed: {
    sortNotifications() {
      return Object.values(this.notifications.reduce((acc, n) => {
        acc[n.hash_group] ??= { hash_group: n.hash_group, messages: [] };
        if (acc[n.hash_group].hash_group === n.hash_group) {
          acc[n.hash_group].messages.push(n);
        }
        return acc;
      }, {}));
    },
    activeLabel() {
      return this.$route.query.label;
    },
    activeFilter() {
      switch (this.activeLabel) {
        case 'new':
          return { is_new: 1 };
        case 'trading':
          return { text_keys: TRADING };
        case 'account':
          return { text_keys: ACCOUNT };
        case 'chats':
          return { text_keys: CHAT };
        default:
          return {};
      }
    },
  },
  watch: {
    activeLabel: 'getNotification',
  },
  async mounted() {
    await this.getNotification();
  },
  methods: {
    async getNotification() {
      const { data: { data, meta } } = await axios.get('/notifications', {
        params: {
          page: this.paginationMeta.current_page,
          per_page: this.paginationMeta.per_page,
          ...this.activeFilter,
        },
      });

      this.notifications = data;
      this.paginationMeta = meta;
    },
    updateNotification(notification) {
      this.notifications = this.notifications.map(
        foundNotification => (foundNotification.id === notification.id ? notification : foundNotification),
      );
    },
  },
};
</script>

<style lang="scss">
@import "/src/@core/scss/base/pages/app-email.scss";
</style>

<style scoped lang="scss">
.notifications-div {
  height: inherit;
}
</style>
